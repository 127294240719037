@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;1,100&family=Titillium+Web:wght@300&display=swap');

/*  --------------- Incubation Centre -----------------  */
.IncubationCentre-Img {
    width: 100%;
    height: 350px;
}


/*  --------------- TABLE BORDER -----------------  */
.my-table,
.my-table > thead > tr > th,
.my-table > tbody > tr > td {
    border: 2px solid #000;
    border-collapse: collapse;
}

.my-table > thead > tr > th {
    font-size: 22px;
}

.my-table > tbody > tr {
    /*margin-top: 100px !important;*/
    padding: 20px 0;
}

.my-table > tbody > tr > td:first-child {
    /*.my-table > tbody > tr > td:first-child {*/
    color: #000;
}

.my-table > tbody > tr > td {
    font-size: 18px;
}

/*  --------------- TABLE BORDER -----------------  */


/*  --------------- Button Visited -----------------  */
.my-white-text {
    color: #fff !important;
}

.my-button-visited:visited {
    color: #fff !important;
}

.list-height li {
    padding: 8px 0 !important;
}

.text-bold {
    font-size: 16px;
}

.no-decoration > li {
    list-style-type: none;
}

/*  ------------------------------------------------  */


/*  --------------- SPINNER CLASSES -----------------  */
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: .75s linear infinite spinner-border;
    animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
    }
}


/*  --------------- MARGIN CLASSES -----------------  */
@media (min-width: 768px) {
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }
}

@media (min-width: 992px) {
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }
}

@media (min-width: 576px) {
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }
}

/*  --------------- UTILITY CLASSES -----------------  */

.invalid-field {
    border: 1px solid orangered !important;
}

.error-msg {
    font-size: 15px;
    color: orangered;
}

.textarea-resize {
    resize: none;
}

/*  --------------- TOP HEADER DESIGN -----------------  */

.top-header-links {
    font-weight: normal !important;
    font-size: 12px !important;
}

/*  --------------- ANT DESIGN -----------------  */
.ant-anchor-link > a {
    /*.ant-anchor-link .nav-link > a {*/
    /*display: inline-block !important;*/
    font-size: 15px !important;
    font-weight: 700 !important;
    padding: 0 !important;
    /*margin: 0 !important;*/
    margin-top: -10px !important;
    /*line-height: 1 !important;*/
    letter-spacing: 0 !important;
    text-transform: uppercase !important;
}

.ant-anchor-link > a {
    /*color: #fff !important;*/
}

/*@media only screen and (max-width: 768px) {*/
/*    .my-widget-title > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {*/
/*        color: #fff !important;*/
/*        !*color: red !important;*!*/
/*    }*/

/*    .my-widget-title-white > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {*/
/*        color: #000 !important;*/
/*        !*color: red !important;*!*/
/*    }*/
/*}*/

@media only screen and (max-width: 992px) {
    .my-widget-title > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {
        color: #fff !important;
    }

    .my-widget-title-white > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {
        color: #000 !important;
    }
}

@media only screen and (min-width: 1200px) {
    .my-widget-title > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {
        color: #000 !important;
    }

    .my-widget-title-white > div > div .ant-anchor-wrapper > .ant-anchor > .ant-anchor-link > a {
        color: #fff !important;
    }
}

.top-header-links {
    font-size: 12px !important;
}

@media only screen and (max-width: 450px) {
    .top-header-links {
        font-size: 9px !important;
        /*font-size: 10px !important;*/
    }
}

/*  --------------- NCC -----------------  */

.certificate {
    color: #0f0f0f !important;
}

.ncc-camps {
    text-align: center;
}

.ncc-camps > img {
    border: 1px solid #000;
    width: 600px;
    /*width: 554px;*/
    /*height: 340px;*/
    height: 380px;
}

/*  --------------------------------  */

.font-size-13 {
    font-size: 13px;
}

.text-blue {
    color: blue;
}

.text-red {
    color: red;
}

.placement-file-links {
    text-decoration: underline !important;
}

.placement-file-links:hover {
    color: blue !important;
}

.placement-file-links:active {
    color: blue !important;
}

.placement-file-links:visited {
    color: blue !important;
}

.my-hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}

/*  --------------- Image Style -----------------  */

.imageEffect2 {
    width: 200px;
    height: 200px;

    /*width: 100%;*/
    /*height: 360px;*/

    overflow: hidden;
    margin: 0px auto 10px auto;
}


.imageEffect2 img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.imageEffect2:hover img {
    transform: scale(1.5);
}

/*  --------------- Download Links -----------------  */

.download-links {
    color: #C21717 !important;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 400;
}

.download-links:hover {
    color: #f33a45 !important;
}

/*  --------------- NSS -----------------  */

#nss-pdf-link {
    color: #C21717 !important;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
}

#nss-pdf-link:hover {
    /*color: #eb2935 !important;*/
    color: #f33a45 !important;
}

/*  --------------------------------  */
/*  --------------------------------  */
/*  --------------------------------  */


:root {
    --main-font-family-1: 'Roboto', sans-serif;
    --main-font-family-2: 'Titillium Web', sans-serif !important;
}

.my-font-style {
    font-family: 'Roboto', sans-serif;
    font-family: 'Titillium Web', sans-serif !important;
}

.counter-text {
    color: #000 !important;
    /*color: #C21717 !important;*/
    font-weight: bolder;
    font-family: 'Roboto', sans-serif;
    font-family: 'Titillium Web', sans-serif !important;
}

.my-font-size {
    font-size: 15px;
}

.my-sub-titles {
    font-size: 16px;
}

.my-sub-titles-15 {
    font-size: 15px;
}

.my-bold-font {
    font-weight: 600;
    /*font-weight: bold;*/
}

.my-bold-font-2 {
    font-weight: 600;
}

.my-font-justify {
    text-align: justify;
}

.text-theme-colored2 {
    color: #C21717 !important;
}

.pb-70 {
    padding-bottom: 70px;
}

.pt-70 {
    padding-top: 30px;
}

/*  --------------- BUTTON STYLE -----------------  */

.btn-theme-colored2:hover {
    color: #fff !important;
    background-color: #eb2935 !important;
    border-color: #ea202c !important;
}

/* --------------- LIST STYLE ----------------- */

ul.list {
    list-style: outside none none;
    margin-top: 13px;
    padding-left: 0;
}

ul.list li {
    margin: 5px 0;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
}

ul.list li:before {
    border: none;
    color: #a9a9a9;
    content: "\f111";
    display: inline-block;
    font-family: fontawesome;
    font-size: 9px;
    margin-right: 10px;
    padding: 0;
    left: 0;
    position: absolute;
    top: 5px;
}

ul.list li:hover:before {
    background: none;
    border-color: transparent;
}

ul.list li a {
    font-family: var(--main-font-family-1);
    font-family: var(--main-font-family-2);
    text-decoration: none;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
}

/* -------------------------------- */

/* HEADER SECTION */
#header-gradient {
    background: linear-gradient(0deg, transparent, rgba(0, 0, 0, .8)), linear-gradient(0deg, transparent 10%, rgba(0, 0, 0, .6)) !important;
}

/* ABOUT SECTION */
.btn-theme-colored2 {
    background-color: #C21717;
}

.btn-theme-colored2 {
    color: #fff;
    background-color: #C21717;
    border-color: #C21717;
}

.bg-theme-iitd {
    padding-bottom: 15px !important;
    background-color: #fff4f4 !important;
}

.text-theme-colored2 {
    color: #C21717 !important;
}

.text-theme-colored {
    color: #212331 !important;
}

.double-line-bottom-centered-theme-colored-2 {
    /*margin-bottom: 20px;*/
    /*margin-top: 8px;*/
    padding-bottom: 5px;
    position: relative;
}

.double-line-bottom-centered-theme-colored-2:after {
    border-radius: 8px;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    right: 0px;
    margin: 0 auto;
    position: absolute;
    width: 64px;
    background: #C21717;
}

.double-line-bottom-centered-theme-colored-2:before {
    border-radius: 8px;
    bottom: 0;
    content: "";
    height: 6px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    position: absolute;
    width: 24px;
    background: #C21717;
}

.double-line-bottom-theme-colored-2 {
    margin-bottom: 20px;
    margin-top: 8px;
    padding-bottom: 5px;
    position: relative;
}

.double-line-bottom-theme-colored-2:after {
    border-radius: 8px;
    bottom: 1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 64px;
    background: #C21717;
}

.double-line-bottom-theme-colored-2:before {
    border-radius: 8px;
    bottom: -1px;
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 24px;
    background: #C21717;
}

/*  --------------------------------  */

/* COUNTER SECTION */
.counter-text {
    font-size: 60px;
}

.custom_fact_name > h6 {
    /*color: #c21717 !important;*/
    color: #fff !important;
}

.stat-count > span {
    color: #fff !important;
    /*color: #0f0f0f !important;*/
    /*color: #C21717 !important;*/

    font-size: 48px;
    font-weight: bold;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-20 {
    margin-right: 100px !important;
}

/*  --------------------------------  */

/* TABLE STYLE */
.my-table-style > thead > tr > th {
    text-align: center;
    vertical-align: top;
}

.my-table-style > tbody > tr > td > a.mail-to:hover {
    text-decoration: none !important;
    /*color: #C21717 !important;*/
}

.my-table-style > tbody > tr > td > a.mail-to {
    text-decoration: none !important;
    color: #C21717 !important;
}


.row-span-2 {
    /*text-align: center;*/
    /*vertical-align: center !important;*/
    height: 100px !important;
    line-height: 100px !important;
}

/*  --------------------------------  */

/* FOOTER SECTION */
.footer-bg > img {
    width: 100%;
    /*height: 300px;*/
}

.footer-col-1 {
    min-height: 330px;
}


/*.footer-logo-address {*/
/*    border-right: 2px dotted;*/
/*}*/

.footer-logo-address > img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.mini-headings {
    color: #fff;
    font-size: 16px;
    font-weight: 400;

    /*line-height: 1.42857143;*/
    /*letter-spacing: .1px;*/
    /*color: #333333;*/
}

.mini-sub-headings {
    color: #9d9d9d !important;
    font-size: 15px !important;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    margin-bottom: 15px;
}

.contact-details > li > a {
    text-transform: lowercase !important;
    text-decoration: none;
    color: #c5c5c5 !important;
    font-size: 16px;
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.contact-details > li {
    margin: 0;
    /*padding: 0;*/
}

.social-links {
    /*width: 150px;*/
    margin: 0 auto;
    /*margin: 0;*/
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.social-links > li {
    /*margin: 0;*/
    /*padding: 0;*/

    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid #c5c5c5;
    border-radius: 50%;
}

.social-links > li:hover {
    color: #fff !important;
    background-color: #777777;
}

.social-links > li > a {
    /*align-self: center;*/
    color: #fff !important;
}

.social-links > li > a:hover {
    color: #fff !important;
}

.headings-with-border {
    color: #fff !important;
    position: relative;
    padding-bottom: 10px;
}

.headings-with-border:after {
    background: #C21717;

    border-radius: 10px;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
}

.quick-link-1 > li {
    padding: 5px 0;
    border-bottom: 1px dashed #333333;
}

.quick-link-1 > li > a {
    color: #fff !important;
    text-decoration: none;
    text-transform: capitalize;

    font-family: 'Roboto', sans-serif;
    font-family: 'Titillium Web', sans-serif !important;
    font-size: 16px;
}

/*  --------------------------------  */

/* Scroll To Top Button */
.scroll-to-top {
    background-color: #383946;
    border-radius: 0;
    display: inline-block;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
    margin-left: 3px;
    text-align: center;
    width: 45px;

    position: fixed;
    bottom: 20px;
    right: 20px;
}

.scroll-to-top > a > i {
    color: #fff !important;
    font-size: 42px;
}

#footer-bottom {
    background-color: rgb(43, 45, 59) !important;
    padding: 12px 0;
}

#footer-bottom h5 {
    font-size: 13px;
    /*color: #fff !important;*/
    color: #77787D !important;
    font-weight: 600;
}

.chairman_bog {
    height: 300px;
}
/*  --------------------------------  */

@media only screen and (min-width: 992px) {
    .footer-col-1 {
        border-right: 2px dotted #7d7d7d !important;
    }
}

/*@media (min-width: 576px) {*/
@media only screen and (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .text-lg-right {
        text-align: right !important;
    }
}

@media only screen and (max-width: 990px) {
    /*@media only screen and (max-width: 768px) {*/
    .text-center-sm {
        text-align: center !important;
    }
}

@media only screen and (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .text-md-right {
        text-align: right !important;
    }
}

@media only screen and (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .text-lg-right {
        text-align: right !important;
    }
}

/*  --------------------------------  */
.counter-number {
    color: #fff;
    font-weight: 600;
}

.counter-title {
    color: #000;
    font-weight: 600;
}

.image_Div {
    text-align: center;
}

.image_Div img {
    border: 4px solid #C21717;
    border-radius: 6px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .section-padding {
        padding: 25px 0 !important;
    }

    .counter-number {
        font-size: 16px !important;
    }

    .counter-title {
        font-size: 12px !important;
    }

    .image_Div {
        overflow: auto;
    }

    .image_Div img {
        width: 100%;
        height: 450px;
        /*width: 280px;*/
        /*height: 280px;*/
    }

    .coming-soon-logo {
        width: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .section-padding {
        padding: 35px 0 !important;
    }

    .counter-number {
        font-size: 22px !important;
    }

    .counter-title {
        font-size: 16px !important;
    }

    .image_Div img {
        width: 90%;
        height: 450px;
        /*width: 320px;*/
        /*height: 320px;*/
    }

    .coming-soon-logo {
        width: 450px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .section-padding {
        padding: 60px 0 !important;
    }

    .counter-number {
        font-size: 28px !important;
    }

    .image_Div img {
        width: 100%;
        /*height: 550px;*/

        /*width: 360px;*/
        height: 360px;
    }

    /*.coming-soon-logo {*/
    /*    width: 550px;*/
    /*}*/
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .section-padding {
        padding: 70px 0 !important;
    }

    .counter-number {
        font-size: 32px !important;
    }

    .image_Div img {
        /*width: 100%;*/
        height: 400px;

        /*width: 360px;*/
        /*height: 360px;*/
    }

    /*.counter-title {*/
    /*    font-size: 16px !important;*/
    /*}*/
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .section-padding {
        padding: 80px 0 !important;
    }

    .counter-number {
        font-size: 35px !important;
    }

    .counter-title {
        font-size: 18px !important;
    }

    .image_Div img {
        height: 420px;
    }
}
/*  --------------------------------  */
