.imgWidth {
  border: 10px solid rgba(220, 211, 211, 0.41);
    /*border-radius: 10px;*/
}

.desc {
    text-align: justify;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .imgWidth {
        width: 250px;
        margin-bottom: 3.5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .imgWidth {
        width: 250px;
        margin-bottom: 3.5rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .imgWidth {
        width: 300px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .imgWidth {
        width: 100%;
    }

    .desc {
        font-size: 16px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .imgWidth {
        width: 300px;
    }

    .desc {
        font-size: 18px;
    }
}