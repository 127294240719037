.topimage {
    padding: 15rem 0 5rem !important;
}

.breadcrumbBox {
    padding-top: 90px;
}

.title-area h2, .title-area small {
    color: #FFFFFF !important;
}

.breadcrumb > li, .breadcrumb > li > a {
    color: #FFFFFF !important;
}

/*Aryan CSS  Start*/

.font-22 {
    font-size: 20px;
    line-height: 55px;
}

.font-20 {
    font-size: 20px;
    line-height: 50px;
}

.table-pad {
    padding: 10px;
}

.fa-1x {
    font-size: 1.2em;
}

.p-lh {
    line-height: 3rem;
}

/*Aryan CSS  End*/

/*   CSS BY VASU     */
.table-head-red-V {
    background-color: #c21717;
    color: #ffffff;
}

.margin-25-V {
    margin-top: 25px;
    margin-bottom: 25px;
}

.table-title-V {
    font-size: 20px;
    padding: 5px 0px 5px 10px;
}

.table-body-padd-V {
    padding: 18px 10px !important;
}

.card_V {
    padding: 2.0rem !important;
    border-radius: 20px;
    transition: transform .8s;
    -webkit-box-shadow: 0px 0px 37px -22px rgba(140, 140, 140, 1);
    -moz-box-shadow: 0px 0px 37px -22px rgba(140, 140, 140, 1);
    box-shadow: 0px 0px 37px -22px rgba(140, 140, 140, 1);
}

.card_V:hover {
    transform: scale(1.02);
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0 !important;
}

.theme_btn_V:hover {
    color: aliceblue !important;
}

.theme_btn_V {
    color: aliceblue !important;
    background-color: #c21717;
}

.theme_btn_V:hover {
    color: aliceblue !important;
    background-color: rgba(210, 25, 25, 0.91);
}

.mt-l_V {
    margin-top: 100px;
}

.faculty-img_V {
    width: 240px !important;
    height: 240px !important;
    border-radius: 22px !important;
    padding: 10px;
}

.theme_btn_V {
    color: #ffffff !important;
    background-color: #c21717;
}

/*.theme_btn_V:hover{*/
/*    color: aliceblue!important;*/
/*    background-color: #c21717 !important;*/
/*}*/
.m-7-v {
    margin: 18px 0px;
}

.margin-x-25-v {
    margin: 0px 25px;
}

.m-7-v {
    margin: 18px 0px;
}

.margin-x-25-v {
    margin: 0px 25px;
}

.mysection {
    border-right: 1px solid #eceaea;
}

.theme-btn {
    background-color: #C21717 !important;
    color: white !important;
}

.theme-btn:hover {
    color: white !important;
}

.theme-btn:active {
    color: white !important;
}

.theme-btn:visited {
    color: white !important;
}

.theme-btn:active {
    color: white !important;
}

#myButtons {
    padding: 20px 0px;
}

.pd-6_12 {
    padding: 6px 12px !important;
}

.myImage_A {
    padding-bottom: 50px;
}

.myButton_A {
    margin-bottom: 10px;
}

.Image_A {
    border-radius: 10px !important;
    margin-bottom: 10px;
}

.Image_A2 {
    margin-left: 70px;
}


.imageEffect {
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 0px auto 10px auto;
}

.imageEffect img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.imageEffect:hover img {
    transform: scale(1.5);
}

.myColor_A {
    color: #333333;
}


.text-left-10 {
    padding-left: 45px;
}

.staffresiDIV {
    width: 100%;
    overflow: hidden;
    margin: 0px auto 10px auto;
    border: 1px solid #c72c1c;
}

.staffresiDIV > img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.staffresiDIV:hover img {
    transform: scale(1.5);
}

.text-white{
    color: #ffffff;
}