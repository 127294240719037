.post-card {
    position: relative;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 15%);
    background: #fff;
    min-width: 180px;
    height: 300px;
    transition: all 0.3s ease 0s;
}
.post-img {
    height: 250px;
    overflow: hidden;
}
.post-card img {
    display: block;
    width: 120px;
    margin-top: 20px;
    border-radius: 10px;
    height: 145px;
    border: 1px solid #ccc;
    padding: 5px;
}
.post-content {
    position: absolute;
    bottom: 0;
    background: #FFFFFF;
    width: 100%;
    padding: 15px 10px 10px 10px;
    min-height: 125px;
}
.team-title {
    margin: 0;
    padding: 0 0 10px;
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
.team-subtitle {
    margin: 0;
    padding: 0 0 10px;
    color: #595959;
    font-size: 12px;
    font-weight: bold;
    text-align: center !important;
}
.team-description {
    color: #595959;
    font-size: 14px;
    line-height: 1.8em;
    display: none;
    text-align: center !important;
}
.team-description p {
    color: #595959;
    font-size: 13px;
    text-align: center !important;
}
.post-card:hover, .hover {
    box-shadow: 0px 1px 20px 0px rgba(168, 19, 37, 0.38);
    cursor: pointer;
}
.post-card:hover .post-img, .hover .post-img {
    transform: scale(1.1);
    opacity: 0.9;
}
.post-card:hover .team-description{
    display: block !important;
}