/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.bannerStyle {
    width: 100%;
    margin-top: 80px;
    height: 580px;
}

@media screen  and (max-width: 900px) {
    .bannerStyle {
        width: 100%;
        margin-top: 120px;
        height: 240px;
    }
}

.vidFrame {
    height: 600px;
    width: 100%;
}

/* 11-11-2022 */

.ant-anchor-link-title {
    text-decoration: none;
    color: white !important;
}

.ant-anchor-link-title:hover {
    color: white !important;
}

.ant-anchor-link-title:visited {
    color: white !important;
}

div.fullscreen-image > img {
    width: 200px;
    height: 200px !important;
}
